body,
html,
#root,
.wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bar-wrapper {
  margin-top: 5px;
}

.tree-wrapper {
  height: calc(100vh - 200px);
}

.bitsorter-tree-wrapper {
  height: calc(100vh - 200px);
}

#simple-tabpanel-0{
  height: calc(100vh - 200px);
}

#simple-tabpanel-1{
  height: calc(100vh - 200px);
}

.next,
.previous {
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-width: 40px !important;
}

.collapse,
.addBit,
.expand {

  margin-left: 10px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-width: 40px !important;
}

.formControl{
  min-width:565px !important;
  margin-top:10px !important;
}

.rstcustom__node {
  height: unset !important;
  position: relative !important;
  top: unset !important;
}

.MuiListItem-container {
  border: 1px solid #909090;
  margin-bottom: 5px !important;
  border-radius: 4px;
}

.internalNode {
  background-color: LightCoral !important;
  color: white !important;
  font-weight: bold !important;
}

.internalNode > div > span {
  color: white !important;
  font-weight: bold !important;
}

.internalNode > div > p {
  color: white !important;
}

.MuiListItemText-root{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

